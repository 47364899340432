<template>
  <div class="card card-custom grid-stack-item-content">
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("partner.partners") }}</h3>
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-primary btn-hover"
          @click="$router.push({ name: 'adminPartnersCreate' })"
        >
          <i class="fal fa-plus"></i>
          {{ $t("partner.partnerCreate") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadPartners">
        <b-table
          responsive
          :items="partners"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="showDetails(data.item.id)"
            >
              <i class="fal fa-pen"></i>
            </b>
          </template>
          <template #cell(name)="data">
            <div class="row flex-wrap py-3">
              <div class="col-auto symbol symbol-50 py-0">
                <div
                  class="symbol-label symbol-label-profile"
                  :style="
                    data.item.logo
                      ? 'background-image: url(' +
                        data.item.logo.link +
                        '); background-color: #ffffff;'
                      : ''
                  "
                ></div>
              </div>
              <div class="col-auto flex-grow-1 py-0">
                <span class="d-block">{{ data.item.name }}</span>
                <span class="text-muted">{{ data.item.number }}</span>
              </div>
            </div>
          </template>
          <template #cell(active)="data">
            <span
              v-if="data.item.active"
              class="label label-lg label-light-success label-inline"
            >
              {{ $t("general.active") }}
            </span>
            <span v-else class="label label-lg label-light-danger label-inline">
              {{ $t("general.inactive") }}
            </span>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/components/Tools/modifiers";

import Partners from "@/components/Admins/Partners/partners";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  components: { TableWrapper },
  data() {
    return {
      partners: [],
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "name",
          label: this.$t("table.partner"),
          sortable: true
        },
        {
          key: "active",
          label: this.$t("table.status"),
          sortable: true
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  mounted() {
    this.loadPartners();
    this.$root.$on("partnersTableRefresh", () => {
      this.refreshCard();
    });
  },
  methods: {
    loadPartners() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Partners.getAll(params, cancelToken)
        .then(response => {
          this.partners = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },
    showDetails(id) {
      this.$router.push({ name: "adminPartnersDetails", params: { id: id } });
    }
  }
};
</script>

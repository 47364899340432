<template>
  <div>
    <!--begin::Partners-->
    <div v-if="userType === 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <PartnersTable></PartnersTable>
      </div>
    </div>
    <!--end::Partners-->

    <div v-if="userType !== 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Denied></Denied>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PartnersTable from "@/components/Admins/Partners/Table";
import Denied from "@/components/SYS/errors/Denied";

export default {
  components: {
    PartnersTable,
    Denied
  },
  data() {
    return {
      userType: null
    };
  },
  mounted() {
    this.userType = this.$store.getters.userType;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminPartners"),
        route: ""
      },
      {
        title: this.$t("general.overview"),
        route: "/admin/partners"
      }
    ]);
  }
};
</script>
